<template>
  <el-dialog
    title="设置指定机型"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1400px"
    @close="btnClose"
  >
    <el-form :model="filterData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="所属品牌" prop="brandId">
        <el-select v-model="filterData.brandId" filterable clearable placeholder="请选择品牌">
          <el-option v-for="item in oldBrand" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="查询机型" prop="name">
        <el-input v-model="filterData.name" clearable placeholder="请输入机型名称" />
      </el-form-item>
      <el-form-item label="机型标记" prop="mark">
        <el-select v-model="filterData.mark" clearable placeholder="请选择机型标记">
          <el-option v-for="item in markOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="机型来源" prop="channel">
        <el-select v-model="filterData.channel" multiple placeholder="请选择">
          <el-option v-for="item in channelList" :key="item.key" :label="item.value" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" round @click="getData">查询</el-button>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: space-between">
      <el-card style="width:100%">
        <div style="font-weight: bold; height: 40px">添加机型</div>
        <GlobalTable
          ref="leftTable"
          v-loading="leftLoading"
          :columns="tableColumns"
          :data="leftList"
          :currentPage="leftCurrent"
          :total="originLeftList.length"
          :pageSize="pageSize"
          :max-height="500"
          layout="total, sizes, prev, pager, next, jumper"
          isSelection
          @handleSelectionChange="(v) => (leftSelectList = v)"
          @handleCurrentChange="getLeftData"
          @handleSizeChange="handleSizeChange"
          >
          <!-- :isPagination="false" -->
            <el-table-column label="机型标记" prop="mark" slot="mark" align="center">
              <template slot-scope="{ row }">
                {{ optionsFilter(row.mark, markOptions) || '--' }}
              </template>
            </el-table-column>
          </GlobalTable>
      </el-card>
      <div class="arrows-box">
        <i
          :disabled="!rightSelectList.length"
          class="arrow-btn left"
          @click="rightSelectList.length && move('left')"
        />
        <i
          :disabled="!leftSelectList.length"
          class="arrow-btn right"
          @click="leftSelectList.length && move('right')"
        />
      </div>
      <el-card style="width:70%">
        <div style="font-weight: bold; height: 40px">
          已添加机型（{{ idList.length }}个）
        </div>
        <GlobalTable
          ref="rightTable"
          v-loading="rightLoading"
          :columns="tableColumns"
          :data="rightList"
          :currentPage="rightResult.current"
          :total="rightResult.total"
          :pageSize="pageSize"
          :max-height="500"
          isSelection
          :isPagination="false"
          @handleSelectionChange="(v) => (rightSelectList = v)"
          @handleCurrentChange="getRightData"
          >
            <el-table-column label="机型标记" prop="mark" slot="mark" align="center">
              <template slot-scope="{ row }">
                {{ optionsFilter(row.mark, markOptions) || '--' }}
              </template>
            </el-table-column>
          </GlobalTable>
      </el-card>
    </div>
    <div slot="footer">
      <el-button size="small" @click="btnClose">关闭</el-button>
      <el-button size="small" type="primary" @click="confirm">确认添加</el-button>
    </div>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "ModelSelectDia",
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
    machineTypeId: [String, Number],
    filter: String,
    // 左请求列表忽略的id（已选）
    omitIds: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      dialogVisible: false,
      // 搜索条件相关
      showForm: false,
      filterData: {
        brandId: '',
        name: '',
        mark: '',
        channel: []
      },
      channelList: [],
      oldBrand: [],
      markOptions: [
        { label: '折叠屏', value: '00'},
        { label: '曲面屏', value: '01'},
      ],
      // Table数据相关，list展示的数据，SelectList选中的列表，Result接口返回
      rightList: [],
      leftList: [],

      originLeftList: [],

      leftSelectList: [],
      rightSelectList: [],

      leftLoading: false,
      rightLoading: false,

      leftCurrent: 1,
      rightResult: {},

      pageSize: 50,
      leftTotal: 0,
      rightTotal: 0,
      tableColumns: [
        { label: '品牌', prop: "brandName" },
        { label: '机型名称', prop: "name" },
        { label: '机型来源', prop: "channel" },
        { slotName: "mark" },
      ],

      // 选中的id列表
      idList: [],
    };
  },
  methods: {
    open() {
      // 清空筛选
      this.filterData = {}
      this.showForm = true
      this.idList = JSON.parse(JSON.stringify(this.ids)) || []
      this.dialogVisible = true;
      this.getResidentList()
      this.getChannelList()
      this.getData()
    },
    getData() {
      // this.getLeftData()
      // this.getRightData()
      this.leftLoading = true
      this.rightLoading = true
      this.rightList = []
      this.leftList = []
      _api.listAddedUnAdd({
        ...this.filterData,
        // filter: '00',
        filter: this.filter,
        machineModelIds: this.idList,
        machineTypeId: this.machineTypeId,
        
        pageNum: 1,
        pageSize: this.pageSize
      }).then(res => {
        console.log(res)
        this.rightList = res.data.added || []

        this.originLeftList = res.data.unAdd || []
        this.getLeftData(1)
        // this.leftList = res.data.unAdd
        // this.leftList = []
        // this.loadLeftData(res.data.unAdd)
      }).finally(() => {
        this.leftLoading = false
        this.rightLoading = false
      })
    },
    handleSizeChange(num) {
      this.pageSize = num
      this.getLeftData(1)
    },
    getLeftData(pageNum = 1) {
      this.leftCurrent = pageNum
      this.leftList = this.originLeftList.slice((pageNum - 1)*this.pageSize, pageNum * this.pageSize)
      // this.leftLoading = true
      // _api.listByTypeBrandPage({ 
      //   ...this.filterData,
      //   type: '01',
      //   assignMachineModelIds: [...this.omitIds, ...this.idList],
      //   pageNum,
      //   pageSize: this.pageSize
      // }).then(res => {
      //   this.leftResult = res.data
      //   this.leftList = res.data.records || []
      // }).finally(() => {
      //   this.leftLoading = false
      // })
    },
    getRightData(pageNum = 1) {
      this.rightLoading = true
      _api.listByTypeBrandPage({
        ...this.filterData,
        type: '02',
        assignMachineModelIds: this.idList,
        pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.rightResult = res.data
        this.rightList = res.data.records || []
      }).finally(() => {
        this.rightLoading = false
      })
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
    usePagination(list = [], pageNum, pageSize) {
      return list.slice((pageNum - 1) * pageSize, pageNum * pageSize)
    },
    // 获取旧机品牌列表
    getResidentList() {
      _api.ResidentList().then(res => {
        const list = res.data.map(item => ({
          value: item.id,
          label: item.name
        }))
        this.oldBrand = list
      })
    },
    getChannelList() {
      _api.getMachineChannelList().then(res => {
        this.channelList = res.data
      })
    },
    confirm() {
      this.$emit(
        "confirm",
        JSON.parse(JSON.stringify(this.idList))
      );
      this.btnClose();
    },
    btnClose() {
      this.dialogVisible = false;
      this.showForm = false
    },
    move(type) {
      // 数据移动
      const selectList =
        type === "left" ? this.rightSelectList : this.leftSelectList;
      const selectIds = selectList.map((item) => item.id);
      if (type === "right") {
        this.rightList.push(...selectList);
        
        this.leftList = this.leftList.filter(
          (item) => !selectIds.includes(item.id)
        );
        this.originLeftList = this.originLeftList.filter(
          (item) => !selectIds.includes(item.id)
        );

        this.idList = [...this.idList, ...selectIds]
      } else {
        this.leftList.push(...selectList);
        this.originLeftList.push(...selectList);

        this.rightList = this.rightList.filter(
          (item) => !selectIds.includes(item.id)
        );
        this.idList = this.idList.filter((id) => !selectIds.includes(id))
      }
      // this.$emit('input', this.rightList.map(item => item.id))
    },
  },
};
</script>

<style lang="scss" scoped>
.arrows-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .arrow-btn {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 2px 6px 6px 0px rgba(102, 102, 102, 0.16);
    &[disabled] {
      cursor: not-allowed;
      &.left {
        background: url("~@/assets/images/storeshop/icon_hs_zj.png");
      }
      &.right {
        background: url("~@/assets/images/storeshop/icon_hs_yj.png");
      }
    }
    &.left {
      background: url("~@/assets/images/storeshop/icon_qhs_zj.png");
    }
    &.right {
      margin-top: 16px;
      background: url("~@/assets/images/storeshop/icon_ls_yj.png");
    }
  }
}
</style>