<template>
  <div>
    <el-dialog title="修改配置" :visible.sync="visible" :close-on-click-modal="false" width="600px">
      <div style="margin-bottom: 20px">该功能项适用机型配置：</div>
      <el-radio-group v-model="form.machineConfig">
        <el-radio label="00">全部机型</el-radio>
        <el-radio label="01">指定机型</el-radio>
      </el-radio-group>
      <div style="color: red; margin-top: 20px">
        {{ form.machineConfig == '00' ? '切换到全部机型后，该功能将默认全部机型均使用' : '如需切换到指定机型，需设置完成机型才可切换' }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">{{form.machineConfig == '00' ? '确定' : '下一步' }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "ModelConfigDia",
  props: { },
  data() {
    return {
      visible: false,
      form: {
        id: '',
        machineConfig: '00'
      }
    };
  },
  methods: {
    open(row) {
      const { id, machineConfig } = row
      // this.row = row
      this.form = { id, machineConfig }
      this.visible = true
    },
    close() {
      this.visible = false
    },
    confirm() {
      // const { machineConfig } = this.form
      // if (machineConfig == '00') {
      //   _api.saveMachineConfig(this.form).then(res => {
      //     this.$message.success("修改成功");
      //     this.visible = false
      //     this.$emit('confirm')
      //   })
      // } else {
      //   this.$emit('next', JSON.parse(JSON.stringify(this.form)))
      //   this.visible = false
      // }
      this.$emit('confirm', JSON.parse(JSON.stringify(this.form)))
      this.visible = false
    }
  }
};
</script>